@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/researchRoom/variables.scss";

.intro {
  background: $researchRoomGradient;
}

.videoWrpClassName {
  padding-bottom: 47.3%; // 3840×1816 resolution
  height: 0;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.introAnimatedTitle {
  color: #fffb84;
  text-align: left;

  @include mqMaxWidth(1046px) {
    text-align: center;
  }

  @include mqMaxWidth(801px) {
    text-align: left;
  }

  @include mqMaxWidth(659px) {
    text-align: center;
  }

  @include mqMaxWidth(531px) {
    text-align: left;
  }

  @include mqMaxWidth(442px) {
    text-align: center;
  }
}

.description {
  max-width: 825px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 138.889% */
}
