@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/evercall/ui/styles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.slidersWrapper {
  margin-top: 68px;

  @include mqMaxWidth($screenM) {
    margin-top: 30px;
  }

  :global(.slick-slider) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;

    @include mqMaxWidth($screenM) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  :global(.slick-list) {
    max-width: 820px;
    padding: 55px;
    width: 100%;
    bottom: 53px;
    left: 32px;
    flex-shrink: 0;

    @include mqMaxWidth(1228px) {
      margin-left: -60px;
    }

    @include mqMaxWidth($screenM) {
      max-width: 100%;
      margin-left: 0;
      padding: 50px 45px 0;
      left: 0;
      bottom: 0;
    }
  }

  @include mqMaxWidth($screenM) {
    :global(.slick-slide) {
      pointer-events: none;
    }

    :global(.slick-active) {
      pointer-events: inherit;
    }
  }
}

.dotsList {
  display: flex;
  flex-direction: column;
  max-width: 455px;
  padding: 2px 0 0 18px;
  margin: 0;
  flex-shrink: 1;

  :global(.slick-active) {
    .dotButton {
      opacity: 1;
    }

    .description {
      transition: max-height 0.5s ease, opacity 0.5s ease;
      opacity: 1;
      max-height: 500px;
      transition-delay: 0.5s;
    }
  }

  @include mqMaxWidth($screenM) {
    flex-direction: row;
    width: auto;
    padding: 0;
  }
}

.dot {
  list-style: none;
  margin-bottom: 50px;
  position: relative;

  &:last-child {
    margin-bottom: 0;

    .dotButton:before {
      display: none;
    }
  }

  @include mqMaxWidth($screenM) {
    margin-bottom: 0;
  }
}

.dotButton {
  background: none;
  border: 0;
  display: flex;
  flex-direction: row;
  opacity: 0.5;
  cursor: pointer;

  &:before {
    content: '';
    width: 1px;
    height: calc(100% + 51px);
    position: absolute;
    background-color: $secondary2Color;
    left: 17px;
    z-index: -1;
  }

  @include mqMaxWidth($screenM) {
    &:before {
      width: 9px;
      height: 1px;
      left: inherit;
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.dotNumber {
  background-color: #fff;
  color: $secondaryColorHover;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.14px;
  width: 34px;
  height: 34px;
  display: inline-block;
  border: solid 1px $titleColor;
  line-height: 33px;
  border-radius: 50%;
  flex-shrink: 0;
  padding-left: 1px;
  margin-right: 20px;
  position: relative;
  z-index: 1;

  @include mqMaxWidth($screenM) {
    margin: 0 15px;
  }
}

.dotText {
  @include mqMaxWidth($screenM) {
    display: none;
  }
}

.slideText {
  display: none;
  transform: translateX(-50px);
  padding: 40px 40px 0;

  @include mqMaxWidth($screenM) {
    display: block;
  }
}

.title {
  text-align: left;
  font-family: $baseFontFamily;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  margin: 3px 0 0;

  @include mqMaxWidth($screenM) {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
  }
}

.description {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: $baseTextColor;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;

  @include mqMaxWidth($screenM) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin: 20px 0;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mqMaxWidth($screenM) {
    overflow: auto;
    opacity: 1;
    max-height: max-content;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.13px;
  }
}

.img {
  max-width: 710px;
  width: 100%;
  margin-right: 70px;
  box-shadow: 0 0 50px rgba(107, 120, 113, 0.15);

  &Label {
    margin: 22px 0 0;
    padding-right: 12%;
    text-align: center;
    color: $baseTextColor;
    font-family: Roboto;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.12px;
  }

  @include mqMaxWidth($screenM) {
    margin-right: 0;
    max-width: calc(100% - 90px);
    margin-bottom: -5px;

    &Label {
      display: none;
    }
  }
}

.iconWrapper {
  background-color: $backgroundFormColor;
  color: $secondaryColorHover;
  width: 34px;
  height: 34px;
  border: solid 1px $titleColor;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    width: 12px;
    height: 12px;
  }
}

.dotsWrapper {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include mqMaxWidth($screenM) {
    flex-direction: row;
  }
}

.slidersWrapperWithDotsDescription {
  :global(.slick-list) {
    padding: 0 55px;
  }

  .slideText {
    padding: 0 40px;
  }

  @include mqMaxWidth($screenM) {
    :global(.slick-list) {
      padding: 0 35px 20px;
    }

    .dotsList {
      flex-direction: column;
    }

    .slideText {
      display: block;
      transform: translateX(-40px);
      padding: 28px 40px 0;
    }
  }
}

.slidersWrapperIsReversed {
  :global(.slick-slider) {
    flex-direction: row;
  }

  :global(.slick-list) {
    left: -70px;
  }

  .dotsList {
    padding: 0;
    transform: translateX(-68px);
  }

  .img {
    transform: translate(-22px, 20px);
    max-width: 715px;

    &Label {
      max-width: 620px;
      padding-right: 0;
      transform: translate(30px, -15px);
    }
  }

  @include mqMaxWidth(1228px) {
    :global(.slick-list) {
      left: -10px;
    }
  }

  @include mqMaxWidth($screenM) {
    :global(.slick-slider) {
      flex-direction: column-reverse;
    }

    :global(.slick-list) {
      left: 0;
    }

    .dotsList {
      transform: translateX(0);
      padding: 0;
    }

    .img {
      transform: translateX(-30px);
      max-width: 100%;
    }

    .slideText {
      padding: 0 40px;
    }
  }
}
