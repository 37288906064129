@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/researchRoom/variables.scss";

.actionsWrp {
  text-align: center;
}
.bookDemoButton {
  margin-right: 20px;
  transition: background-color $animationTime + ms;

  @include mqMaxWidth($screen640) {
    margin: 0;
  }
}

