@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/dataroom/ui/components/Landing/researchRoom/variables.scss";

.exploreButton {
  transition: background-color $animationTime + ms;
}

.headerWrapper {
  margin-bottom: 18px;


  @include mqMaxWidth($screen640) {
    margin-bottom: 0;
  }
}

.detailsContainer {
  > div:first-child {
    max-width: 454px;
  }
}


.featuresButton {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mqMaxWidth(1070px) {
    margin: 15px 40px 0;
  }

  @include mqMaxWidth($screenM) {
    margin: 30px 40px 0;
  }

  @include mqMaxWidth(640px) {
    margin: 30px 40px 0;
  }
}

.img {
  box-shadow: none;
}

.dotsList {
  max-width: 470px;
}

.title {
  @include mqMaxWidth($screenM) {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: $titleColor;
  }
}
